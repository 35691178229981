import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { BaseModel } from "../../models/@base/base.model";

export class HttpService {
    
    protected baseUrl: string;

    constructor(protected httpClient: HttpClient) {
        this.baseUrl = environment.api;
    }

    protected getAuthenticated<T>(path: string) : Observable<T> {
        return this.httpClient.get<T>(
            environment.api + path, this.getAuthOptions()
        );
    }

    protected postAuthenticated<T>(path: string, body: T) : Observable<BaseModel<T>> {
        return this.httpClient.post<BaseModel<T>>(
            environment.api + path, body, this.getAuthOptions()
        );
    }

    protected putAuthenticated<T>(path: string, body: T) : Observable<BaseModel<T>> {
        return this.httpClient.put<BaseModel<T>>(
            environment.api + path, body, this.getAuthOptions()
        );
    }

    protected deleteAuthenticated<T>(path: string) : Observable<T> {
        return this.httpClient.delete<T>(
            environment.api + path, this.getAuthOptions()
        );
    }

    protected skip(pageSize: number, page: number): number {
        return pageSize * (page - 1);
    }

    private getAuthOptions () {
        return {  
            headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('mw_token')
            }
        }
    }
}