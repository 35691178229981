import { Injectable } from "@angular/core";
import { NbDialogService } from "@nebular/theme";
import { Subject } from "rxjs";
import { ConfirmDialogComponent } from "../../@theme/components/confirm-dialog/confirm-dialog.component";

@Injectable()
export class ConfirmationService {

  constructor(private dialogService: NbDialogService) {

  }
  
  public confirmationSubject: Subject<boolean> = new Subject<boolean>();

  public show(message: string, title: string = 'Confirme', confirmLabel: string = 'Sim', cancelLabel: string = 'Não') {
    this.dialogService.open(ConfirmDialogComponent, {
        hasBackdrop: true,
        closeOnBackdropClick: false,
        context: {
          title: title,
          message: message,
          confirmLabel: confirmLabel,
          cancelLabel: cancelLabel,
          confirmationSubject: this.confirmationSubject
        }
      });
  }
}