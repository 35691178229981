import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Subject } from 'rxjs';
import { ConfirmationService } from '../../../@core/utils/confirmation.service';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  constructor(protected ref: NbDialogRef<ConfirmDialogComponent>) { }

  @Input() public title: string;
  @Input() public message: string;
  @Input() public confirmLabel: string;
  @Input() public cancelLabel: string;
  @Input() public confirmationSubject: Subject<boolean>;

  ngOnInit() {

  }

  confirm() {
    this.confirmationSubject.next(true);
    this.ref.close();
  }

  cancel() {
    this.confirmationSubject.next(false);
    this.ref.close();
  }


}
