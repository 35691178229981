<nb-card>
  <nb-card-header>
     {{title}}
  </nb-card-header>
  <nb-card-body>
    <p>
      {{message}}
    </p>
  </nb-card-body>
  <nb-card-footer>
    <button type="button" nbButton status="success" class="btn-salvar-modal" (click)="confirm()">{{confirmLabel}}</button>
    <button nbButton status="danger" (click)="cancel()">{{cancelLabel}}</button>
  </nb-card-footer>
</nb-card>