import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseModel } from "../models/@base/base.model";
import DadosInstitucionais from "../models/configuracoes/dados-institucionais.model";
import SEO from "../models/configuracoes/seo.model";
import Site from "../models/configuracoes/site.model";
import Static from "../models/configuracoes/static.model";
import { HttpService } from "./@base/http.service";

@Injectable()
export class ConfiguracoesService extends HttpService  {

    constructor(protected http: HttpClient) {
        super(http);
    }

    public statics: Static;

    public getStaticUrl(diretorio: string) {
        return this.statics.urlBaseEstaticos + diretorio + "/";
    }

    public getStatics(): Observable<BaseModel<Static>> {
        return this.getAuthenticated('/configuracoes/static');
    }

    public getDadosInstitucionais(): Observable<BaseModel<DadosInstitucionais>> {
        return this.getAuthenticated('/configuracoes/dados-institucionais');
    }

    public getSEO(): Observable<BaseModel<SEO>> {
        return this.getAuthenticated('/configuracoes/seo-global');
    }

    public getSite(): Observable<BaseModel<Site>> {
        return this.getAuthenticated('/configuracoes/site');
    }

    public putDadosInstitucionais(dados: DadosInstitucionais): Observable<BaseModel<DadosInstitucionais>> {
        return this.putAuthenticated('/configuracoes/dados-institucionais', dados);
    }

    public putStatics(statics: Static): Observable<BaseModel<Static>> {
        return this.putAuthenticated('/configuracoes/static', statics);
    }

    public putSEO(seo: SEO): Observable<BaseModel<SEO>> {
        return this.putAuthenticated('/configuracoes/seo-global', seo);
    }

    public putSite(site: Site): Observable<BaseModel<Site>> {
        return this.putAuthenticated('/configuracoes/site', site);
    }

} 