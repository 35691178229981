import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-grid-preview',
  templateUrl: './image-grid-preview.component.html',
  styleUrls: ['./image-grid-preview.component.scss']
})
export class ImageGridPreviewComponent implements OnInit {

  @Input() basePath: string;
  @Input() image: string;

  constructor() { }

  ngOnInit() {

  }

}
