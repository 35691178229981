import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { take } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable()
export class AuthService {

    constructor(private http: HttpClient) {

    }

    public onAuthComplete: Subject<boolean> = new Subject<boolean>();

    public autenticar(): void {
        const body = {
            singleToken: '2E3EFF98-13A4-4D63-B76B-AAC3C3DE18F6'
        };

        this.http.post<any>(`${environment.api}/auth/token`, body).pipe(take(1)).subscribe({
            next: response => {
                if(response && response.authenticated) {
                    this.onAuthComplete.next(true);
                    localStorage.setItem('mw_token', response.accessToken);
                } else {
                    this.onAuthComplete.next(false);
                }
            },
            error: () => {
                this.onAuthComplete.next(false);
            }
        });
    }
}